import React from 'react';
import ReactDOM from 'react-dom';
import {Strip, VStack, HStack, Spacer} from './AuxiliaryViews'



export default function FeatureTitleStrip(props) {
        return (
            <Strip>
                <Spacer size="l"/>
                <VStack align='left'>
                    <div className="featureTitle">{props.title}</div>
                    <div className="featureSubtitle">{props.subtitle}</div>
                </VStack>
                <Spacer size="s"/>
            </Strip>
        )
}