import React from 'react';
import ReactDOM from 'react-dom';
import {Strip, VStack, HStack, Spacer} from './AuxiliaryViews'
import { Link } from 'react-router-dom';

const FancyLink = React.forwardRef((props, ref) => (
    <a ref={ref}>💅 {props.children}</a>
  ))
  // You can now get a ref directly to the DOM button:
  const ref = React.createRef()
  

export default class Header extends React.Component {

    
      
    render() {
        return (
            <div className="headerBar">
                <Spacer size="s"/>
                <HStack align="left">
                <Spacer size="s"/>
                    <Link to="/">
                        <LogoButton ref={ref}/>
                    </Link>
                </HStack>
                <Spacer size="s"/>
            </div>
        )
    }
}



const LogoButton = React.forwardRef((props, ref) => {
    return <HStack>
            <div className="logoImageContainer">
                <img src="./assets/HomeriseLogo.png" alt="logo" width="30px" height="30px"/>
            </div>
            <Spacer size="xs"/>
            <div className="appNameSmall">Homerise</div>
        </HStack>
    }
)