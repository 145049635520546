import React from 'react';
import {Strip, VStack, HStack, Spacer, Paragraph, Separator} from './AuxiliaryViews'

export default function MinorFeaturesStrip(props) {

    var minorFeatures = props.minorFeatures || []
    var blocks = minorFeatures.map((item, index) => {
        return <MinorFeatureBlock key={index} name={item.name} description={item.description} />
    })

    return (
        <Strip>
            <Spacer size="m"/>
            <Separator/>
            <Spacer size="m"/>
            <HStack align="stretch">
                {blocks}
            </HStack>
            <Spacer size="l"/>
        </Strip>
    )
}

function MinorFeatureBlock(props) {


    return (
        <VStack className="minorFeature" align="top">
            <h3>{props.name}</h3>
            <p>{props.description}</p>
        </VStack>
    )
}