class InfoPageContent{
    constructor(props){
        let {title, sections} = props
        this.title = title
        this.sections = sections
    }
}

class Section{
    constructor(props){
        let {name, paragraphs} = props
        this.name = name
        this.paragraphs = paragraphs
    }
}

class MinorFeature{
    constructor(props){
        let { name, description} = props
        this.name = name
        this.description = description
    }
}

class Strings {

    static Dashboard = {
        title: "Dashboard",
        subtitle: "The last dashboard you might ever need",
        paragraphs: [
            "An automatic dashboard carefully crafted to give you an overview of your entire home and yet fine control over your accessories.",
            "Easily explore your accessories in detail by tapping or long pressing on the panel section of your interest.",
        ] 
    };

    static FloorPlan = {
        title: "Floor Plan",
        subtitle: "Spatial organization for your accessories",
        paragraphs: [
            "Organize and control all your accessories by placing markers and panels on the floor plan of your home.",
            "Markers respond to taps, swipes and panning, and you decide what each gesture will do. A marker can also represent a group of accessories and you can customize its appearance to match your style.",
            "Panels are a convenient way to visualize and interact with multiple accessories. It makes perfect sense for a room or even a panel for your entire home.",
            "Additionally you can choose which markers or panels are shown according to the zoom level, this way you can seamlessly go from controlling a single accessory to your entire home.",
        ] 
    };

    static ARRemote = {
        title: "AR Remote",
        subtitle: "Free your senses with a remote like never before",
        paragraphs: [
            "Go beyond the boundaries of your phone and interact with smart accessories in real life without even looking at the screen!",
            "Place markers on your home using Augmented Reality. Now you will only need to point towards the accessory you want to control and it will appear right at your fingertips so you can keep your eyes where it matters.",
            "Markers in AR get revamped with spatial gestures, and of course, you can customize them too. It will feel like magic!"
        ] 
    };

    static LightingPresets = {
        title: "Lighting Presets",
        subtitle: "Set the ambiance with just a tap",
        paragraphs: [
            "Create a colorful palette from and image and apply it easily on your lightbulbs.",
            "Then you can use lighting presets on markers and panels."
        ] 
    };


    static CreditsSystem = {
        title: "Simple credits system",
        subtitle: "Pay-as-you go. 10 free credits.",
        paragraphs: [
            "Credits allow you to unlock the accessory you want to interact with in Homerise, across all features.",
            "Only interactive accessories (lightbulbs, outlets, blinds, etc) and cameras need credits.",
            "Get started with 10 credits for free!"
        ]
    };



    static PrivacyPolicy = new InfoPageContent({
        title: "Privacy Policy",
        sections: [
            new Section({
                name: "Collected information",
                paragraphs: [
                    "Homerise collects only information necessary to the configuration of the application. This includes anonymous ID's provided by HomeKit as well as any specific configuration performed by the user in the application like markers, floor plans, Augmented Reality data, scenes and presets.",
                    "Your information is store exclusively on your iCloud account, which already provides a cryptography layer."
                ]
            }),
            new Section({
                name: "Shared information",
                paragraphs: [
                    "Homerise does not share your data with any third-party service."
                ]
            }),
            new Section({
                name: "Data deletion",
                paragraphs: [
                    "Since all the information is store in your iCloud account, you may go to your iCloud settings and delete it anytime."
                ]
            })
        ]
    });

    static MinorFeatures = [
        new MinorFeature({
            name: "Presets",
            description: "Define behaviors or states beforehand and apply them easily to your accessories. Presets integrate with markers and automations."
        }),
        new MinorFeature({
            name: "Scenes",
            description: "Create scenes efficiently with groups of actions, dynamic room groups and presets."
        }),
        new MinorFeature({
            name: "iCloud",
            description: "All the information is securely stored in your iCloud account"
        })
    ]

    
}




export default Strings