import React from 'react';
import ReactDOM from 'react-dom';
import {Strip, VStack, HStack, Spacer} from './AuxiliaryViews'

export default function LogoStrip(props){

        return (
            <Strip className="fullHeight logoStrip">
                <HStack>
                    <VStack>
                        <Spacer size="l"/>
                        <div className="logoImageContainer">
                            <img src="./assets/HomeriseLogo.png" alt="logo" width="150px" height="150px" />
                        </div>
                        <Spacer size="s"/>
                        <VStack>
                            <div className="appName">Homerise</div>
                            <div className="slogan">Elevate Your Smart Home</div>
                            <Spacer size="s"/>
                            <a href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1439944386" target="_blank">
                                <img src="./assets/AppStoreBadge.svg"></img>
                            </a>
                            <Spacer size="l"/>
                            <div className="intro">Intuitive and expressive ways to control your smart home with HomeKit.</div>
                            <Spacer size="s"/>
                        </VStack>
                    </VStack>
                </HStack>
            </Strip>
        )
    }

{/* <a>
    <img src="./assets/AppStoreBadge.svg" alt="logo" width="150px" height="150px" />
</a> */}
