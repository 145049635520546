import React from 'react';
import {Strip, VStack, HStack, Spacer, Separator, Paragraph} from './AuxiliaryViews'
import LogoStrip from './LogoStrip'
import FeatureTitleStrip from './FeatureTitleStrip'
import FeatureDescriptionStrip from './FeatureDescriptionStrip'
import FeatureQuoteStrip from './FeatureQuoteStrip'
import MinorFeaturesStrip from './MinorFeaturesStrip'
import Footer from './Footer'
import Strings from './Strings'

export default function HomePage(){


    return (
        <div className="homepage">
            <LogoStrip />
            <FeatureTitleStrip title={Strings.Dashboard.title} subtitle={Strings.Dashboard.subtitle} />
            <FeatureDescriptionStrip paragraphs={Strings.Dashboard.paragraphs} imageName="Dashboard.jpg" altName="Dashboard" reversed={true}/>

            <FeatureTitleStrip title={Strings.FloorPlan.title} subtitle={Strings.FloorPlan.subtitle} />
            <FeatureDescriptionStrip paragraphs={Strings.FloorPlan.paragraphs} videoName="FloorPlanZoom.mov" altName="Floor Plan"/>

            <FeatureTitleStrip title={Strings.ARRemote.title} subtitle={Strings.ARRemote.subtitle} />
            <FeatureDescriptionStrip paragraphs={Strings.ARRemote.paragraphs} imageName="ARRemoteDemo.mov" altName="AR Remote" reversed={true}/>
            
            <FeatureTitleStrip title={Strings.LightingPresets.title} subtitle={Strings.LightingPresets.subtitle} />
            <FeatureDescriptionStrip paragraphs={Strings.LightingPresets.paragraphs} imageName="LightingPresets.jpg" altName="Lighting Presets"/>

            <FeatureTitleStrip title={Strings.CreditsSystem.title} subtitle={Strings.CreditsSystem.subtitle} />
            <FeatureDescriptionStrip paragraphs={Strings.CreditsSystem.paragraphs} imageName="CreditsSystem.jpg" altName="Credits System" reversed={true}/>

            <MinorFeaturesStrip minorFeatures={Strings.MinorFeatures}/>
            <Footer/>
        </div>
    )
}
