import React from 'react';
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router,Routes,Route,Link, useLocation} from "react-router-dom";
import HomePage from './HomePage'
import InfoPage from './InfoPage';
import { INFO_PAGE } from './enums';




// lax.addPreset("fadeInOutTitle", function() {
//     return { 
//         "data-lax-opacity": "0 0, (vh*0.3) 1, (vh*0.7) 1, vh 0",
//         "data-lax-translate-y": "0 -100, (vh*0.3) 0, (vh*0.7) 0, vh 100"
//     }
// })

// lax.addPreset("TitleAnimation", function() {
//     return { 
//         "data-lax-opacity": "(vh*0.6) 1, (vh*0.7) 0",
//         "data-lax-translate-y": "(vh*0.5) 0, (vh*0.6) (vh*0.05), (vh*0.7) (vh*0.2)"
//     }
// })

// lax.addPreset("ParagraphAnimation", function() {
//     return { 
//         "data-lax-opacity": "(-vh*0.1) 0, (vh*0.1) 1, (vh*0.85) 1, (vh*0.95) 0",
//         "data-lax-translate-y": "(vh*0.85) 0, (vh*0.95) (vh*0.05)"
//     }
// })

// lax.addPreset("FeatureImageAnimation", function() {
//     return { 
//         "data-lax-opacity": "(-vh*0.7) 0, (-vh*0.5) 1, (vh*0.5) 1, (vh*0.7) 0",
//         "data-lax-translate-y": "(vh*0.5) 0, (vh*0.7) (vh*0.1)"
//     }
// })


// lax.addPreset("LogoImageContainerAnimation", function() {
//     return { 
//         "data-lax-translate-y": "(-vh*0.25) (vh*0.5), (vh*0.25) 0"
//     }
// })


function App(props) {

    return (
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/privacy-policy" element = {<InfoPage page={INFO_PAGE.PRIVACY_POLICY}/>} />
            <Route path="/terms" element = {<InfoPage page={INFO_PAGE.TERMS}/>} />
            <Route path="/contact" element = {<InfoPage page={INFO_PAGE.CONTACT}/>} />
            <Route path="/" element = {<HomePage/>} />
          </Routes>
        </div>
      </Router>
    );
  }
  

ReactDOM.render(
    <App />,
    document.getElementById('root')
);



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}