import React from 'react';
import {Strip, VStack, HStack, Spacer, Paragraph} from './AuxiliaryViews'
import { useMediaQuery } from 'react-responsive'

export default function FeatureDescriptionStrip(props) {

    const isMobile = useMediaQuery({ maxWidth: 800 })

    var paragraphs = props.paragraphs || []
    var pComponents = paragraphs.map((item, index) => {
        return <Paragraph key={index} text={item} />
    })

    var media;
    if (props.imageName != null){
        media = <img src={"./assets/" + props.imageName} className="featureImage" alt={props.altText}/>
    }
    else if (props.videoName != null){
        media = <video muted autoPlay loop src={"./assets/" + props.videoName} className="featureImage" alt={props.altText}/>
    }


    if(isMobile){
        return (
            <Strip>
                <div className="featureDescription">{pComponents}</div>
                <div className="featureMedia">
                    {media}
                </div>
            </Strip>
        )
    }
    

    const descriptionBlock = <div className="featureDescription width50">{pComponents}</div>
    const imageBlock = (
        <div className="featureMedia width50">
            {media}
        </div>
    )

    var blocks = [descriptionBlock, imageBlock]

    if(props.reversed) { blocks.reverse()}

    return (
        <Strip>
            <HStack>
                {blocks}
            </HStack>
        </Strip>
    )

}