import React from 'react';
import PropTypes from 'prop-types';

export class VStack extends React.Component {

    render(){
        var align = 'vStack-center'

        if (this.props.align !== undefined){
            align = 'vStack-' + this.props.align
        }

        var className = this.props.className || ""

        return <div className={"vStack " + align + " " + className}>{this.props.children}</div>
    }   
}

VStack.propTypes = {
    align: PropTypes.oneOf(['left','center','right','top','bottom'])
};

export class HStack extends React.Component {

    render(){
        var align = 'hStack-center'

        if (this.props.align !== undefined){
            align = 'hStack-' + this.props.align
        }

        var className = this.props.className || ""

        return <div className={"hStack " + align + " " + className}>{this.props.children}</div>
    }   
}

HStack.propTypes = {
    align: PropTypes.oneOf(['left','center','right','top','bottom', 'stretch'])
};

export function Strip(props) {
    return (
        <div className={"strip " + props.className}>
            <div className="stripContent">
                {props.children}
            </div>
        </div>
    )
}

export class Spacer extends React.Component {

    render(){
        var size = 'spacer-m'

        if (this.props.size != undefined){
            size = 'spacer-' + this.props.size
        }

        return <div className={"spacer " + size}></div>
    }   
}
Spacer.propTypes = {
    size: PropTypes.oneOf(['xs','s','m','l', 'xl'])
};

export class Separator extends React.Component {

    render(){
        return <div className={"separator"}></div>
    }   
}


export function Paragraph(props) {
    return (
        <p>{props.text}</p>
    )
}