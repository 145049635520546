import React from 'react';
import {Strip, VStack, HStack, Spacer} from './AuxiliaryViews'
import { Link } from 'react-router-dom';

export default class Footer extends React.Component {


    render() {
        return (
            <Strip className="footerStrip">
                <Spacer size="m"/>
                <HStack>
                    <HStack>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Spacer size="s"/>
                        <Link to="/terms">Terms</Link>
                        <Spacer size="s"/>
                        <Link to="/contact">Contact</Link>
                    </HStack>
                    <Spacer size="m"/>
                    <VStack>
                        <HStack>
                            <a className="socialMediaLink" href="https://instagram.com/homerise.app" target="blank">
                                <img src="./assets/Instagram.svg" width="29px" title="Instagram" alt="Instagram"/>
                            </a>
                            <a className="socialMediaLink" href="https://facebook.com/homeriseapp" target="blank">
                                <img src="./assets/Facebook.svg" width="29px" title="Facebook" alt="Facebook"/>
                            </a>
                        </HStack>
                    </VStack>
                </HStack>
                <Spacer size="m"/>
            </Strip>
        )
    }
}
