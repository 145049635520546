import React from 'react';
import ReactDOM from 'react-dom';
import {Strip, VStack, HStack, Spacer} from './AuxiliaryViews'

export default class FeatureQuoteStrip extends React.Component {

    render() {
        return (
            <Strip className="bg-1">
                <Spacer size="m"/>
                <VStack align='center'>
                    <div className="featureQuote">“ {this.props.quote} ”</div>
                    <div className="featureQuoteAuthor">— {this.props.author}</div>
                </VStack>
                <Spacer size="m"/>
            </Strip>
        )
    }
}